const initBlock = async function( $block ) {

	const serviceAreasMap = () => {
		const $areasMap = $block.find( '.service-areas-map' );
		if ( !$areasMap.length ) { return false; }

		const toggleHoverClass = ( add, $element ) => {
			const area = $element.attr( 'data-area' );
			const $title = $areasMap.find( '.service-areas-map__area-title' ).filter( `[data-area="${area}"]` );
			const action = add ? 'addClass' : 'removeClass';
			$element[action]( 'is-hover' );
			$title[action]( 'is-hover' );
		};

		$areasMap.find( '.path-map-area' ).hover(
			function() {
				toggleHoverClass( true, $( this ) );
			},
			function() {
				toggleHoverClass( false, $( this ) );
			}
		);

	};
	serviceAreasMap();

};

themeUtils.loadBlock( initBlock, 'areas', '.section-areas' );
